import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/welcome.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/welcome',
        name: 'root',
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import('../views/welcome.vue')
    },
    {
        path: '/sceglianimale',
        name: 'sceglianimale',
        component: () => import('../views/sceglianimale.vue')
    },
    {
        path: '/memorizza/:opzione',
        name: 'memorizza',
        component: () => import('../views/memorizza.vue')
    },
    {
        path: '/componi/:opzione',
        name: 'componi',
        component: () => import('../views/componi.vue')
    },
    {
        path: '/complimenti',
        name: 'complimenti',
        component: () => import('../views/complimenti.vue')
    },
    {
        path: '/peccato',
        name: 'peccato',
        component: () => import('../views/peccato.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
